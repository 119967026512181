/* Font Links ============================ */
/* @import url("https://use.typekit.net/tkn1umx.css"); */
@import url("https://use.typekit.net/lvh3taz.css");
* {
  margin: 0;
  padding: 0;
}
.floating {
  box-shadow: 0px 10px 15px 10px rgba(0, 0, 0, 0.15);
}

html,
body,
#root {
  background-color: var(--background-primary);
  color: var(--text-primary);
  cursor: none;
}
.cursor {
  position: fixed;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: white;
  mix-blend-mode: difference; /* Inverts the background it's over */
  pointer-events: none;
  z-index: 10000;
  transform: translate(-50%, -50%);
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.parallax {
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.lineContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.lineContainer span {
  display: inline-block;
  width: 1px;
  background-color: rgba(111, 111, 111, 0.369);
  height: 70vh;
}
.wordsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 500%;
  font-family: ambroise-firmin-std, serif;
}

.borderBottom {
  border: 2px solid var(--font);
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.blurbContainer {
  display: flex;
  justify-content: center;
}
.card {
  background-color: var(--popout);
  padding: 4.5%;
  border-radius: 30px;
  border: solid black 1px;
}
.star path {
  fill: var(--star);
  /* filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)); */
  /* filter: drop-shadow(rgba(0, 0, 0, 0.5) 0px 5px 10px 0px inset); */
  /* box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 10px 0px inset !important; */
}
.none {
  display: none;
}
.shadow-twenty-two {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.shadow-twelve {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
button {
  width: 20%;
  padding: 1% 2%;
  font-weight: 600;
  font-size: large;
  letter-spacing: 10px;
  transition: all 0.3s ease-in;
  border-radius: 50px;
  background-color: var(--accent-secondary);
  color: var(--text-primary);
  border: 1.5px solid var(--text-secondary);
}

button:hover {
  border: 1.5px solid var(--text-secondary);
  background-color: var(--text-primary);
  color: var(--accent-secondary);
}
.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-c-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.d-even {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.d-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loadingContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-family: degular-display, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 10vw;
}

.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1000;
}
.loadingContainer div {
  display: flex;
}
.progressBarContainer {
  width: 100%;
  height: 5px; /* You can adjust the height of the bar */
  background-color: #f0f0f0; /* Light gray background for the bar */
  margin-top: 20px; /* Space between text and progress bar */
  border-radius: 3px; /* Optional: Rounded corners */
}

.progressBar {
  height: 100%;
  background-color: #4caf50; /* Green color for the progress bar */
  transition: width 0.2s ease; /* Smooth transition for progress */
}

.cap {
  text-transform: capitalize;
}
.sprite {
  width: 40px;
  height: 40px;
  transform: scale(1.5);
  background-image: url("../../Images/ShorterSpriteSheet.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  filter: contrast(1.2) brightness(1.1);
}

/*animation to scroll across screen */
@-moz-keyframes scroll-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}
@-webkit-keyframes scroll-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes scroll-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .wordsContainer {
    font-size: 200%;
  }
  .miniBlurb {
    font-size: 150%;
  }
  .lineContainer span {
    height: 100vh;
  }
}
@media screen and (min-width: 500px) and (max-width: 700px) {
  .wordsContainer {
    font-size: 300%;
    text-align: center;
  }
}
