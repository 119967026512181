.tools-container {
  text-align: center;
  margin: 13rem 0;
}
.tools-container .header {
  width: 100%;
  margin-bottom: 6%;
}
.tools-container h1 {
  font-size: 5rem;
  margin: 0 1%;
  color: var(--accent-primary);
}
.svg-container {
  display: flex;
  justify-content: end;
  align-items: center;
  height: min-content;
  position: relative;
  bottom: 50px;
}
.drag-svg {
  padding-right: 50px;
}
.tools-container .cards-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.tools-container .cards {
  width: 100%;
  display: flex;
}
.icon-card {
  height: 200px;
  min-width: 200px;
  border: solid black 1px;
  border-radius: 20px;
  margin: 0 20px;
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--card);
}
.skill-information {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.skill-information h2 {
  margin-top: 4%;
  color: var(--text-tertiary);
}
.skill-information h4 {
  margin-top: 5px;
  font-style: italic;
  color: var(--text-tertiary);
}
.skill-information p {
  /* color: #d9d9d9; */
  padding-top: 2%;
  width: 60%;
  color: var(--text-secondary);
}
.resumeBtn {
  margin-top: 3%;
}
@media screen and (min-width: 300px) and (max-width: 499px) {
  .tools-container h1 {
    font-size: 4em;
  }
  .resumeBtn {
    width: 90%;
  }
}
