.aboutContainer {
  position: relative;
  margin-top: 10%;
}
.aboutContainer main {
  /* margin: 10% 0; */
  padding: 0 4%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
.aboutContainer main div {
  text-align: center;
}
.aboutContainer main h1 {
  font-size: 3em;
  font-weight: 100;
  text-transform: uppercase;
  font-style: italic;
}
.aboutContainer main p {
  margin-top: 3%;
  text-align: center;
  font-size: 20px;
  padding: 0 20%;
  color: var(--text-primary);
}
.aboutContainer .processSection h1 {
  text-align: center;
  padding: 2%;
  font-size: 500%;
}

.aboutContainer .processSection .sticky_parent {
  height: 200vh; /*creates the sticky scroll for the parent*/
}

.aboutContainer .processSection .sticky {
  overflow: hidden;
  position: sticky;
  top: 0;
  height: 100vh;
}
.aboutContainer .processSection .scroll_section {
  position: absolute;
  top: 0;
  height: 100%;
  width: 500vw;
  will-change: transform;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5vw;
}
.aboutContainer .endBlurb {
  text-align: start;
  margin: 0% 5vw;
  width: 30%;
}
.aboutContainer .endBlurb h1 {
  font-size: 400%;
}
.aboutContainer .endBlurb p {
  font-size: 120%;
}
.aboutContainer .processSection .item h5 {
  font-size: 120%;
  color: var(--accent);
}
.aboutContainer .processSection .item h4 {
  font-size: 300%;
  color: var(--highlight-text);
}
.aboutContainer .processSection .item p {
  font-size: 150%;
  width: 50%;
}

@media screen and (min-width: 300px) and (max-width: 499px) {
  .aboutContainer main h1 {
    font-size: 2em;
    width: 90%;
  }
  aboutContainer main p {
    font-size: 15px;
  }
}
