.navbarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.5%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  overflow: hidden;
  font-size: 150%;
  text-transform: uppercase;
}
.navbarContainer:before {
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);

  filter: blur(10px);
}

.navbarContainer.scrolled {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.sticky {
  position: fixed;
  top: 0;
  left: 0;
}
.navbarContainer h3 {
  letter-spacing: 5px;
  background-color: var(--accent-primary);
  color: var(--accent-secondary);
  padding: 1% 10px;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
}
.navbarContainer ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  /* width: 36%; */
}
.navbarContainer ul li {
  font-weight: 600;
  font-size: 20px;
  white-space: nowrap;
}
.inactive {
  pointer-events: none;
  opacity: 0;
}
.inactive:hover::before,
.inactive:hover::after,
.inactive:hover span {
  transform: none; /* Prevent hover transformation */
  transition: none; /* Disable the transition on hover */
}

.inactive:hover {
  cursor: default;
}
.navbarContainer ul li a {
  overflow: hidden;
  position: relative;
  display: inline-block;
  color: var(--text-secondary);
}
.navbarContainer ul li a::before,
.navbarContainer ul li a::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
}
.navbarContainer ul li a::before {
  background-color: #000000;
  height: 2px;
  bottom: 0;
  transform: scaleX(0);
  transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);
}
.navbarContainer ul li a::after {
  content: attr(data-replace);
  font-style: italic;
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);
  color: #000000;
}

.navbarContainer ul li a:hover::before {
  /* transform-origin: 0% 50%; */
  transform: scaleX(1);
}
.navbarContainer ul li a:hover::after {
  transform: translate3d(0, 0, 0);
}

.navbarContainer ul li a span {
  display: inline-block;
  transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);
}

.navbarContainer ul li a:hover span {
  transform: translate3d(-200%, 0, 0);
}
.navbarContainer ul li p {
  position: relative;
  left: -20px;
  top: -30px;
  font-size: 15%;
}
.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  width: 97%;
}
.frosted {
  z-index: 100;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  background-color: rgba(196, 196, 196, 0.11);
}

@media (min-width: 300px) and (max-width: 500px) {
  .navbarContainer {
    padding: 2%;
    height: 80px;
  }

  .navbarContainer ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .navbarContainer ul li {
    font-size: 20px; /* Smaller font size for navigation items */
    margin-bottom: 10px; /* Add space between items */
  }

  .navbarContainer ul li a {
    font-size: 14px; /* Smaller text size for links */
  }

  .navbarContainer h3 {
    font-size: 18px; /* Adjust the header size */
    padding: 1% 5px; /* Adjust padding of the header */
  }

  .navbarContainer.scrolled {
    width: 100%; /* Make sure navbar spans full width when scrolled */
  }
}
