footer {
  padding: 4%;
  width: 85% !important;
  border-radius: 20px;
  border: solid #000000 1px;
}
.footer {
  background-color: var(--accent-primary);
  color: var(--text-secondary);
  margin: 3%;
  position: relative;
  top: -30px;
}
footer main {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
footer main section h1 {
  text-transform: uppercase;
  width: 250px;
  font-size: 45px;
}
footer main section ul {
  margin: 20px 0;
  color: #000000;
}
footer main section ul li {
  margin: 10px 0;
}
footer main a {
  color: #000000;
}
footer main p {
  color: #000000;
}
footer div {
  text-align: center;
}
footer .pushPinImg {
  width: 30%;
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  footer .pushPinImg {
    display: none;
  }
}
