:root {
  --frosted: rgba(196, 196, 196, 0.11);
  --primary: rgb(183, 173, 154);
  --accent: rgb(174, 114, 45);
  --secondary: rgb(21, 22, 20);
  --second-accent: rgb(138, 67, 24);
}
/* Clean Theme */
.theme--clean {
  --background-primary: #ffffff;
  --background-secondary: rgb(21, 22, 20);
  --text-primary: rgb(21, 22, 20);
  --text-secondary: rgb(21, 22, 20);
  --accent-primary: #d9d9d9;
  --accent-secondary: #ffffff;
  --callout-box: #cecece;
  --card: #ffffff;
  --star: rgb(21, 22, 20);
}
/* Light Theme */
.theme--light {
  --background-primary: rgb(183, 173, 154);
  --background-secondary: rgb(21, 22, 20);
  --text-primary: #ffffff;
  --text-secondary: whitesmoke;
  --text-tertiary: rgb(21, 22, 20);
  --accent-primary: rgb(138, 67, 24);
  --accent-secondary: rgb(174, 114, 45);
  --callout-box: #000000;
  --card: whitesmoke;
  --star: rgb(174, 114, 45);
}

/* Dark Theme */
.theme--dark {
  --background-primary: rgb(21, 22, 20);
  --background-secondary: rgb(183, 173, 154);
  --text-primary: rgb(174, 114, 45);
  --text-secondary: rgb(183, 173, 154);
  --text-tertiary: whitesmoke;
  --accent-primary: rgb(174, 114, 45);
  --accent-secondary: rgb(138, 67, 24);
  --callout-box: #000000;
  --card: whitesmoke;
  --star: #d9d9d9;
}
