.workContainer {
  width: 100%;
  align-items: center;
}

.projectsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  width: 100%;
  max-width: 1200px;
  padding: 16px;
  margin: 0 auto;
}
.allProjects {
  margin-bottom: 200px;
  width: 100%;
  text-align: center; /* Center the button horizontally */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectCard {
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.projectImg {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.index {
  font-size: 2em;
  color: var(--accent-primary);
  margin-top: 10px;
  position: relative;
  width: 100%;
  left: 270%;
}
.githubBtn {
  margin: 20px auto 0;
  padding: 10px 20px;
}

.githubBtn a {
  text-decoration: none;
  color: inherit;
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .projectsContainer {
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    margin: 0;
  }
  .projectImg {
    width: 90%;
    height: auto;
    object-fit: cover;
  }
  .index {
    font-size: 2em;
    color: var(--accent-primary);
    margin-top: 10px;
    position: relative;
    width: 100%;
    left: 70%;
  }
  .githubBtn {
    margin: 0;
    width: 90%;
    padding: 10px 20px;
  }
}
