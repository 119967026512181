.homeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--text-primary);
}
.newHomeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.newHomeContainer main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10em;
  width: 60%;
}

.newHomeContainer main h1 {
  margin-top: 0.5em;
  font-size: 10vw;
  text-align: center;
  width: 100vw;
  white-space: nowrap;
  letter-spacing: 20px;
  font-family: degular-display, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  z-index: 2;
}

.newHomeContainer main h5 {
  width: 100vw;
}
.newHomeContainer .scrollContainer {
  position: absolute;
  top: 100%;
  overflow: hidden;
  width: 100vw;
}
.svg-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.animated-svg {
  width: 100%;
  height: auto;
  opacity: 0.5;
}
.blurbContainer {
  /* height: 100vh; */
  margin-top: 15em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.blurbContainer .photos {
  z-index: 90;
  position: absolute;
  right: 10%;
  top: -7em;
}
.blurbContainer img {
  border-radius: 200px;
  height: 200px;
  width: auto;
  transform: scaleX(-1);
}
.aboutMeBlurb {
  background-color: var(--callout-box);
  font-size: 160%;
  line-height: 150%;
  max-width: 60%;
  text-align: justify;
  padding: 2%;
  z-index: 2;
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .svg-background {
    top: 2%;
  }
  .newHomeContainer main {
    flex-direction: column;
    text-align: center;
    margin: 0;
  }
  .aboutMeBlurb {
    max-width: 90%; /* Allow for more text space on mobile */
    font-size: 20px;
  }

  .blurbContainer img {
    width: 150px; /* Reduce image size */
    height: 150px;
  }
}
