/**/
.projectContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.projectContainer section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.projectContainer section .header {
  display: flex;
  flex-direction: column;
  text-align: start;
}
.projectContainer section header section h1 {
  font-size: 4em;
  font-style: italic;
}
.projectContainer section header section h5 {
  font-size: 1.5em;
}
.projectContainer .languages {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 10%;
}
.projectContainer .languages li {
  border: 1px dotted var(--accent-primary);
  padding: 10px;
  border-radius: 30px;
}

.projectContainer h6 {
  margin: 2% 0;
  font-size: 1.5em;
  margin-left: 0.5em;
}
.projectContainer section video {
  width: 700px;
  height: 600px;
}
.websiteContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  padding-bottom: 30px;
}
.websiteContainer a,
.back-to-work {
  background-color: var(--text-secondary);
  padding: 1% 3%;
  border-radius: 30px;
  color: var(--accent-primary);
  border: 1px solid var(--accent-primary);
  transition: all ease-in 0.3s;
}
.projectContainer .blurb {
  text-align: justify;
}
.websiteContainer a:hover,
.back-to-work:hover {
  background-color: var(--accent-primary);
  color: var(--text-secondary);
}
.back-to-work {
  position: relative;
  top: 30px;
  left: 20px;
  width: max-content;
}
@media (min-width: 300px) and (max-width: 500px) {
  .projectContainer section {
    flex-direction: column;
  }
  .projectContainer section video {
    width: 90%;
    height: auto;
    margin: 4% 0;
  }
  .projectContainer .blurb {
    padding: 0 5%;
    padding-bottom: 1%;
  }

  .back-to-work {
    top: 10px;
    left: 70%;
    width: max-content;
  }
}
