/* @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
/* IBM Plex Mono */
/*@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap'); */
@import url("https://use.typekit.net/lvh3taz.css");
@font-face {
  font-family: "PPEditorial New Italic";
  src: url(../../utils/Fonts/PPEditorialNew-Italic.otf) format("otf");
}
@font-face {
  font-family: "PPEditorial New Regular";
  src: url(../../utils/Fonts/PPEditorialNew-Regular.otf);
}
@font-face {
  font-family: "PPEditorial New Ultrabold";
  src: url(../../utils/Fonts/PPEditorialNew-Ultrabold.otf);
}
@font-face {
  font-family: "PPEditorial New Ultrabold Italic";
  src: url(../../utils/Fonts/PPEditorialNew-UltraboldItalic.otf);
}
@font-face {
  font-family: "PPEditorial New Ultralight";
  src: url(../../utils/Fonts/PPEditorialNew-Ultralight.otf);
}
@font-face {
  font-family: "PPEditorial New UltralightItalic";
  src: url(../../utils/Fonts/PPEditorialNew-UltralightItalic.otf);
}
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.inter-thin {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}
.inter-italic-thin {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.inter-extralight {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.inter-light {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.inter-regular {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.inter-medium {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.inter-semibold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.inter-bold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.inter-bold-italic {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: italic;
}
.inter-xbold-italic {
  font-family: "Inter", system-ui;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: italic;
}
.inter-black {
  font-family: "Inter", system-ui;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: italic;
}

.ibm-plex-mono-thin {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-mono-extralight {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-mono-light {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-mono-regular {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-mono-medium {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-mono-semibold {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-mono-bold {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 700;
  font-style: normal;
}

.ibm-plex-mono-thin-italic {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 100;
  font-style: italic;
}

.ibm-plex-mono-extralight-italic {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 200;
  font-style: italic;
}

.ibm-plex-mono-light-italic {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 300;
  font-style: italic;
}

.ibm-plex-mono-regular-italic {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 400;
  font-style: italic;
}

.ibm-plex-mono-medium-italic {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 500;
  font-style: italic;
}

.ibm-plex-mono-semibold-italic {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 600;
  font-style: italic;
}

.ibm-plex-mono-bold-italic {
  font-family: "IBM Plex Mono", system-ui;
  font-weight: 700;
  font-style: italic;
}
/* Irina Serif  */
.inria-serif-light {
  font-family: "Inria Serif", serif;
  font-weight: 300;
  font-style: normal;
}

.inria-serif-regular {
  font-family: "Inria Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.inria-serif-bold {
  font-family: "Inria Serif", serif;
  font-weight: 700;
  font-style: normal;
}

.inria-serif-light-italic {
  font-family: "Inria Serif", serif;
  font-weight: 300;
  font-style: italic;
}

.inria-serif-regular-italic {
  font-family: "Inria Serif", serif;
  font-weight: 400;
  font-style: italic;
}

.inria-serif-bold-italic {
  font-family: "Inria Serif", serif;
  font-weight: 700;
  font-style: italic;
}
